import _ from 'lodash';

import { Conditions, Query, SelectWithConditions } from '@breathelife/types';

import {
  hasControlValue,
  hasControlValueQuery,
  isConditionCollection,
  isConditionQuery,
  isConditions,
  isConditionSingleField,
} from './conditions';
import { NON_NODE_ID_TO_KEEP } from './queries';

export function getConditionNodeIds(conditions: Conditions): Set<string> {
  const nodeIds: Set<string> = new Set<string>();

  conditions.conditions.forEach((condition) => {
    if (isConditionSingleField(condition)) {
      nodeIds.add(condition.nodeId);
    }

    if (isConditions(condition) && !isConditionCollection(condition)) {
      getConditionNodeIds(condition).forEach((nodeId) => nodeIds.add(nodeId));
    }

    if (isConditionCollection(condition)) {
      const { collection, conditions: collectionConditions } = condition;
      nodeIds.add(collection);

      if (!_.isEmpty(collectionConditions)) {
        // using `_.isEmpty` because our type checking can miss collection conditions with `conditions: undefined`.
        getConditionNodeIds({ conditions: collectionConditions }).forEach((nodeId) => nodeIds.add(nodeId));
      }
    }

    if (isConditionQuery(condition)) {
      getQueryNodeIds(condition.query).forEach((nodeId) => nodeIds.add(nodeId));
    }

    if (hasControlValue(condition)) {
      nodeIds.add(condition.controlValue);
    }

    if (hasControlValueQuery(condition)) {
      getQueryNodeIds(condition.controlValueQuery).forEach((nodeId) => nodeIds.add(nodeId));
    }
  });

  return nodeIds;
}

function getNodeIdsFromSelectWithCondition(selectWithConditions: SelectWithConditions): Set<string> {
  const nodeIds: Set<string> = new Set();
  nodeIds.add(selectWithConditions.nodeId);
  getConditionNodeIds(selectWithConditions.selectIf).forEach((nodeId) => nodeIds.add(nodeId));

  return nodeIds;
}

function getNodeIdsFromSelect(select: string[]): string[] {
  const selectNodeIds = select.filter((selectItem) => !NON_NODE_ID_TO_KEEP.includes(selectItem));

  return selectNodeIds;
}

export function getQueryNodeIds(query: Query): Set<string> {
  const { select, subQuery, selectWithConditions, selectRepeatable, operatorParams } = query;
  const nodeIds: Set<string> = new Set<string>();

  getNodeIdsFromSelect(select).forEach((nodeId) => nodeIds.add(nodeId));

  if (selectWithConditions) {
    selectWithConditions.forEach((selectCondition) => {
      getNodeIdsFromSelectWithCondition(selectCondition).forEach((nodeId) => nodeIds.add(nodeId));
    });
  }

  if (selectRepeatable) {
    selectRepeatable.forEach((repeatableSelect) => {
      const { select, selectWithConditions, fromCollection } = repeatableSelect;
      nodeIds.add(fromCollection);
      if (select) {
        // select is a string here, not an array
        nodeIds.add(select);
      }
      if (selectWithConditions) {
        getNodeIdsFromSelectWithCondition(selectWithConditions).forEach((nodeId) => nodeIds.add(nodeId));
      }
    });
  }

  if (subQuery) {
    getQueryNodeIds(subQuery).forEach((nodeId) => nodeIds.add(nodeId));
  }

  if (operatorParams) {
    const { fetch } = operatorParams;
    if (fetch?.firstNonEmptyValueAt) {
      fetch.firstNonEmptyValueAt.forEach((nodeId) => nodeIds.add(nodeId));
    }
  }

  return nodeIds;
}
