import {
  AgeRoundingType,
  ComparisonOperator,
  Condition,
  ConditionSingleField,
  DateTimeReference,
  DateUnit,
  QueryOperator,
  ShiftMagnitude,
  TimeRoundingType,
  TimeUnitRoundingType,
} from '@breathelife/types';

/** Helper to create a condition based on the min age rather than the max date of birth */
export function minimumAgeCondition(
  birthDateNodeId: string,
  value: number,
  unit: DateUnit,
  roundingType: AgeRoundingType | TimeUnitRoundingType,
): ConditionSingleField {
  return {
    nodeId: birthDateNodeId,
    operator: ComparisonOperator.lessThanOrEqual,
    controlValueQuery: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateBackwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          shiftMagnitude: ShiftMagnitude.latestDateRespectingShiftValue,
          dateFormat: 'YYYY-MM-DD',
          shiftValue: value,
          timeRoundingType: roundingType,
        },
      },
    },
  };
}

/** Helper to create a condition based on the max age rather than the min date of birth */
export function maximumAgeCondition(
  birthDateNodeId: string,
  value: number,
  unit: DateUnit,
  roundingType: TimeRoundingType,
): ConditionSingleField {
  return {
    nodeId: birthDateNodeId,
    operator: ComparisonOperator.greaterThanOrEqual,
    controlValueQuery: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateBackwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          shiftMagnitude: ShiftMagnitude.earliestDateRespectingShiftValue,
          dateFormat: 'YYYY-MM-DD',
          shiftValue: value,
          timeRoundingType: roundingType,
        },
      },
    },
  };
}

export function futureDateCondition(
  dateNodeId: string,
  value: number,
  unit: DateUnit,
  comparisonOperator: ComparisonOperator,
): Condition {
  return {
    query: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateForwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          dateFormat: 'YYYY-MM-DD',
          shiftValue: value,
          timeRoundingType: AgeRoundingType.none,
        },
      },
    },
    operator: comparisonOperator,
    controlValue: dateNodeId,
  };
}

export function lastIncidentDateCondition(
  dateNodeId: string,
  value: number,
  unit: DateUnit,
  comparisonOperator: ComparisonOperator,
  timeRoundingType: TimeRoundingType = AgeRoundingType.lastBirthday,
): Condition {
  return {
    query: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateBackwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          shiftMagnitude: ShiftMagnitude.latestDateRespectingShiftValue,
          dateFormat: 'YYYY-MM-DD',
          shiftValue: value,
          timeRoundingType,
        },
      },
    },
    operator: comparisonOperator,
    controlValue: dateNodeId,
  };
}
