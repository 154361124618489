import {
  ComparisonOperator,
  ConditionValidationData,
  DateFieldValidation,
  DateTimeReference,
  FieldTypeValidation,
  Timezone,
} from '@breathelife/types';
import { formatDate, getDateFromReferenceValue, getMinOrMaxDate } from '@breathelife/date-helpers';

// Currently only used for date validation type but could be used to compile other forms of validation types
export function generateValidationDataFromValidationType(
  validationType: FieldTypeValidation,
  timezone: Timezone,
  validationData: ConditionValidationData,
): ConditionValidationData {
  const minMaxDateValidationData = Object.values(DateFieldValidation).includes(validationType as DateFieldValidation)
    ? getDateLimitsBasedOnValidationType(validationType as DateFieldValidation, timezone, validationData)
    : {};
  return { ...validationData, ...minMaxDateValidationData };
}

// Returns either the dateMax, the dateMin, or both depending on whether the validation is looking towards the future, the past or at the current date
// The date will shift either a day forward or a day back depending on if the validation includes "OrCurrentDate" in its comparison
function getDateLimitsBasedOnValidationType(
  validationType: DateFieldValidation,
  timezone: Timezone,
  validationData: ConditionValidationData,
): ConditionValidationData {
  // Only used for DatePicker component which just supports 'YYYY-MM-DD'
  // YYYY-MM are displayed in a different component without the calendar functionality
  const dateFormat = 'YYYY-MM-DD';

  const dateCurrent = formatDate(
    getDateFromReferenceValue(DateTimeReference.currentDateTime, timezone).toDate(),
    dateFormat,
  );

  if (
    (validationData.dateMin &&
      validationType === (DateFieldValidation.futureDate || DateFieldValidation.futureOrCurrentDate)) ||
    (validationData.dateMax &&
      validationType === (DateFieldValidation.pastDate || DateFieldValidation.pastOrCurrentDate))
  ) {
    return {};
  }

  switch (validationType) {
    case DateFieldValidation.futureDate:
      return {
        dateMin: getMinOrMaxDate(dateCurrent || '', ComparisonOperator.lessThan, dateFormat),
      };
    case DateFieldValidation.futureOrCurrentDate:
      return {
        dateMin: dateCurrent,
      };
    case DateFieldValidation.pastDate:
      const dateMax = getMinOrMaxDate(dateCurrent || '', ComparisonOperator.greaterThan, dateFormat);
      return {
        dateMax,
      };
    case DateFieldValidation.pastOrCurrentDate:
      return {
        dateMax: dateCurrent,
      };
    case DateFieldValidation.currentDate:
      return {
        dateMax: dateCurrent,
        dateMin: dateCurrent,
      };
    default:
      return {};
  }
}
