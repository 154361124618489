import parseDecimalNumber from 'parse-decimal-number';

// Parses numeric values in either the English or French format
export function parseNumericValue(value?: unknown): number {
  if (typeof value !== 'number' && typeof value !== 'string') return NaN;
  if (typeof value === 'number') return value;

  const floatValue = parseDecimalNumber(value, ',.'); // English format
  if (!isNaN(floatValue)) return floatValue;
  return parseDecimalNumber(value, ' ,'); // French format
}
