import { Dayjs } from 'dayjs';
import _ from 'lodash';

import {
  formatDate,
  getDateFromReferenceValue,
  getEarliestDateRespectingShiftValue,
  getFutureDateLimitRespectingShiftValue,
  getLatestDateRespectingShiftValue,
} from '@breathelife/date-helpers';
import {
  ApplicationContext,
  DateTimeReference,
  DateUnit,
  OperatorResult,
  OperatorValue,
  QueryOperatorParameters,
  QueryOperators,
  ShiftMagnitude,
  Timezone,
} from '@breathelife/types';

import parser from './expressionParser';
import { parseNumericValue } from './helpers/parseNumericValue';

export const queryOperators = (timezone: Timezone, applicationContext: ApplicationContext = {}): QueryOperators => ({
  sum: sumOperator,
  subtract: subtractionOperator,
  multiply: multiplyOperator,
  formula: formulaOperator(applicationContext),
  subtractDates: subtractDatesOperator(timezone),
  countEqual: countEqualOperator,
  fetch: fetchOperator,
  fetchSpecificId: fetchSpecificIdOperator,
  shiftDateBackwards: shiftDateBackwardsOperator(timezone),
  shiftDateForwards: shiftDateForwardsOperator(timezone),
});

export const VALUES_KEY_FORM_FORMULA_PARAMS = 'VALUES';

type FormulaVariables = { [key: string]: OperatorValue };

function sumOperator(values: { [key: string]: OperatorValue }, params?: QueryOperatorParameters): OperatorResult {
  const parsedParams: number[] = parseNumericParams(params?.numeric);
  const parsedValues: number[] = parseNumericValues(values);
  const correctionFactor = 10;
  let numbersToSum: number[] = _.compact([...parsedValues, ...parsedParams]);
  numbersToSum = numbersToSum.map((x) => _.multiply(x, correctionFactor));
  return _.divide(_.sum(numbersToSum), correctionFactor);
}

function subtractionOperator(
  values: { [key: string]: OperatorValue },
  params?: QueryOperatorParameters,
): OperatorResult {
  const parsedParams: number[] = parseNumericParams(params?.numeric);
  const parsedValues: number[] = parseNumericValues(emptyStringValuesAsZero(values));

  const numbers: number[] = [...parsedValues, ...parsedParams];
  const firstNumber = numbers[0] ?? 0;
  const numbersToSubtract = numbers.slice(1);

  const difference = firstNumber - (_.sum(numbersToSubtract) ?? 0);
  return difference;
}

function emptyStringValuesAsZero(values: { [key: string]: OperatorValue }): OperatorValue[] {
  return _.map(values, (value) => (value === '' ? 0 : value));
}

function multiplyOperator(values: { [key: string]: OperatorValue }, params?: QueryOperatorParameters): OperatorResult {
  const parsedParams: number[] = parseNumericParams(params?.numeric);
  const parsedValues: number[] = parseNumericValues(values);
  const numbersToMultiply: number[] = [...parsedValues, ...parsedParams];

  const hasFalsyValues = numbersToMultiply.some((num) => !num);
  if (_.isEmpty(numbersToMultiply) || hasFalsyValues) return 0;
  return _.reduce(numbersToMultiply, (accumulator: number, nextValue: number) => accumulator * nextValue, 1);
}

// Returns the number of days between the first provided days and all subsequent days.
// Will return undefined if the first value is missing or invalid, or if there are no subsequent valid values to compare to.
function subtractDatesOperator(timezone: Timezone) {
  return (values: { [key: string]: OperatorValue }, params?: QueryOperatorParameters): OperatorResult => {
    const dateUnit: DateUnit = params?.dateUnit ?? DateUnit.day;

    const parsedValues: (Dayjs | undefined)[] = parseDateValues(values, dateUnit, timezone);
    const firstValue = parsedValues[0];
    if (!firstValue || !firstValue.isValid()) {
      // No difference can be computed if first value is not provided.
      return undefined;
    }

    const datesToTakeDiffs: Dayjs[] = parsedValues
      .slice(1)
      .filter((value) => value && value.isValid())
      .map<Dayjs>((x) => x as Dayjs);
    if (!datesToTakeDiffs.length) {
      // No difference can be computed if there are no values to compare to.
      return undefined;
    }
    const differences = datesToTakeDiffs.map((date) => firstValue.diff(date, dateUnit, true));
    return Math.round(_.sum(differences));
  };
}

function countEqualOperator(
  values: { [key: string]: OperatorValue },
  params?: QueryOperatorParameters,
): OperatorResult {
  if (typeof params?.controlValue === 'undefined') {
    throw new Error('controlValue must be provided in params');
  }
  const equalValues = Object.values(values).filter((val) => val === params.controlValue);

  return equalValues.length;
}

function fetchOperator(values: { [key: string]: OperatorValue }, params?: QueryOperatorParameters): OperatorResult {
  if (!params?.fetch?.firstNonEmptyValueAt) {
    throw new Error('fetch.firstNonEmptyValueAt must be provided in params');
  }

  const { firstNonEmptyValueAt } = params.fetch;

  const valueEntries = Object.entries(values);

  // Sort valueEntries to ensure consistent results.
  valueEntries.sort((a, b) => {
    const [keyA] = a;
    const [keyB] = b;
    return keyA > keyB ? 1 : -1;
  });

  const keyValueEntry = valueEntries.find(([queryKey, queryValue]) => {
    return (
      typeof queryValue !== 'undefined' &&
      firstNonEmptyValueAt.some((nodeId) => queryKey === nodeId || queryKey.endsWith(`-_-${nodeId}`))
    );
  });

  if (keyValueEntry) {
    const [, value] = keyValueEntry;
    return value;
  }

  // No entry found.
  return undefined;
}

function fetchSpecificIdOperator(
  values: { [key: string]: OperatorValue },
  params?: QueryOperatorParameters,
): OperatorResult {
  if (!params?.fetchSpecificId?.specificValueAt) {
    throw new Error('fetchSpecificId.specificValueAt must be provided in params');
  }

  const { specificValueAt } = params.fetchSpecificId;
  const getMatchValue = params.fetchSpecificId.matchValue ? values[params.fetchSpecificId.matchValue] : '';
  const valueEntries = Object.entries(values);

  if (getMatchValue) {
    const verifyKey = params.fetchSpecificId.matchValue ? params.fetchSpecificId.matchValue : '';
    const filterKey = valueEntries.filter(([queryKey, queryValue]) => {
      if (queryValue == getMatchValue && queryKey != verifyKey) {
        return queryKey;
      }
    });

    const [key] = filterKey[0];
    const keyValueEntry = key.split('_')[0] + `_-${specificValueAt}`;

    if (values[keyValueEntry]) {
      return values[keyValueEntry];
    } else {
      return undefined;
    }
  }

  // No entry found.
  return undefined;
}

type GetParsedDateParams = {
  dateUnit: DateUnit;
  shiftValue: number;
  timezone: Timezone;
  values: { [key: string]: OperatorValue };
};

function getParsedDate({ dateUnit, shiftValue, timezone, values }: GetParsedDateParams): Dayjs {
  const allowedDateUnits = [DateUnit.day, DateUnit.month, DateUnit.year, DateUnit.week];
  if (!allowedDateUnits.includes(dateUnit)) {
    throw Error('The dateUnit must be day, week, month, or year');
  }

  const dateValues: (Dayjs | undefined)[] = parseDateValues(values, dateUnit, timezone);
  if (dateValues.length > 1) {
    throw Error('Only one date can be transformed per shiftDateOperators');
  }

  const dateToTransform = dateValues[0];
  if (!dateToTransform?.isValid()) {
    throw Error('Invalid date was provided');
  }

  // If the shift value is not finite, then the shifted date cannot be computed properly
  if (!isFinite(shiftValue)) {
    throw Error('The shift value must be finite (e.g. no infinity or NaN)');
  }
  return dateToTransform;
}

function shiftDateBackwardsOperator(timezone: Timezone) {
  return (values: { [key: string]: OperatorValue }, params?: QueryOperatorParameters): OperatorResult => {
    const { dateUnit, shiftDateParams } = params ?? {};

    if (!dateUnit || !shiftDateParams) {
      throw Error('dateUnit and shiftDateBackwardsParams must be provided in params');
    }
    const dateToTransform = getParsedDate({ dateUnit, shiftValue: shiftDateParams.shiftValue, timezone, values });

    let diffedDate;
    if (shiftDateParams.shiftMagnitude === ShiftMagnitude.latestDateRespectingShiftValue) {
      diffedDate = getLatestDateRespectingShiftValue(
        shiftDateParams.shiftValue,
        dateUnit,
        shiftDateParams.timeRoundingType,
        dateToTransform as Dayjs,
      );
    } else if (shiftDateParams.shiftMagnitude === ShiftMagnitude.earliestDateRespectingShiftValue) {
      diffedDate = getEarliestDateRespectingShiftValue(
        shiftDateParams.shiftValue,
        dateUnit,
        shiftDateParams.timeRoundingType,
        dateToTransform as Dayjs,
      );
    } else {
      throw Error('Invalid shiftDateBackwardsParams.shiftMagnitude');
    }

    return formatDate(diffedDate, shiftDateParams.dateFormat);
  };
}

function shiftDateForwardsOperator(timezone: Timezone) {
  return (values: { [key: string]: OperatorValue }, params?: QueryOperatorParameters): OperatorResult => {
    const { dateUnit, shiftDateParams } = params ?? {};

    if (!dateUnit || !shiftDateParams) {
      throw Error('dateUnit and shiftDateForwardsParams must be provided in params');
    }

    const dateToTransform = getParsedDate({ dateUnit, shiftValue: shiftDateParams.shiftValue, timezone, values });

    const diffedDate = getFutureDateLimitRespectingShiftValue(
      shiftDateParams.shiftValue,
      dateUnit,
      dateToTransform as Dayjs,
    );

    return formatDate(diffedDate, shiftDateParams.dateFormat);
  };
}

function formulaOperator(applicationContext: ApplicationContext) {
  return function (values: { [key: string]: OperatorValue }, params?: QueryOperatorParameters): OperatorResult {
    if (!params?.formula) {
      throw new Error(`formula must be provided in params`);
    }
    const expr = parser.parse(params?.formula);

    let formulaVariables = {
      [VALUES_KEY_FORM_FORMULA_PARAMS]: formatFormulaVariableNames(setUndefinedValuesToZero(values)), // Also pass a variable called values
      ...values,
      ...params?.formulaParams,
      applicationContext,
    };

    // expr.evaluate will throw an error if an expected variable is not defined.
    formulaVariables = setUndefinedValuesToZero(formulaVariables);
    formulaVariables = formatFormulaVariableNames(formulaVariables);

    const result = expr.evaluate(formulaVariables);
    return result;
  };
}

function setUndefinedValuesToZero(formulaVariables: FormulaVariables): FormulaVariables {
  return _.mapValues(formulaVariables, (value) => value || 0);
}

function formatFormulaVariableNames(formulaVariables: FormulaVariables): FormulaVariables {
  return _.mapKeys(formulaVariables, (value, key) => formatFormulaVariableName(key));
}

export function formatFormulaVariableName(variableName: string): string {
  // Replace `.` with `_`, since `.`'s will be interpreted as property access by expr-eval
  return variableName.replace(/\.|-/g, '_');
}

function parseNumericParams(numericParams?: (string | number)[]): number[] {
  if (!numericParams) return [];

  const invalidParams = numericParams.filter((param: any) => typeof param !== 'string' && typeof param !== 'number');
  if (invalidParams.length > 0) {
    throw new Error(`Invalid parameters given for a query operator ${invalidParams}`);
  }
  return parseNumericValues(numericParams);
}

function parseNumericValues(values: ValuesCollection): number[] {
  return _.map(values, (value) => parseNumericValue(value));
}

type ValuesCollection = { [key: string]: unknown } | unknown[];

export function parseDateValues(
  values: ValuesCollection,
  dateUnit: DateUnit,
  timezone: Timezone,
): (Dayjs | undefined)[] {
  return _.map(values, (value) => parseDateValue(value, dateUnit, timezone));
}

const compatibleDateUnitsWithCurrentTime = [DateUnit.year, DateUnit.month, DateUnit.day];

function parseDateValue(value: unknown, dateUnit: DateUnit, timezone: Timezone): Dayjs | undefined {
  if (value === DateTimeReference.currentDateTime) {
    if (!compatibleDateUnitsWithCurrentTime.includes(dateUnit)) {
      throw Error(
        `DateTimeReference.currentDateTime is not compatible with date unit ${dateUnit}; use year, month or day`,
      );
    }

    return getDateFromReferenceValue(value, timezone);
  }
  if (typeof value !== 'string' || value === '') return undefined;

  try {
    return getDateFromReferenceValue(value, timezone);
  } catch (e: unknown) {
    return undefined;
  }
}
