import {
  ComparisonOperator,
  Condition,
  ConditionCollection,
  ConditionQuery,
  Conditions,
  ConditionSingleField,
  Query,
} from '@breathelife/types';

export function isConditions(condition: Condition): condition is Conditions {
  return typeof (condition as Conditions).conditions !== 'undefined';
}

export function isConditionSingleField(condition: Condition): condition is ConditionSingleField {
  return typeof (condition as ConditionSingleField).nodeId !== 'undefined';
}

export function isConditionQuery(condition: Condition): condition is ConditionQuery {
  return typeof (condition as ConditionQuery).query !== 'undefined';
}

export function hasControlValue(condition: Condition): condition is Condition & { controlValue: string } {
  return typeof (condition as any).controlValue !== 'undefined';
}

export function hasControlValueQuery(condition: Condition): condition is Condition & { controlValueQuery: Query } {
  return typeof (condition as any).controlValueQuery !== 'undefined';
}

export function isConditionCollection(condition: Condition): condition is ConditionCollection {
  return (
    typeof (condition as ConditionCollection).collection !== 'undefined' &&
    typeof (condition as ConditionCollection).collectionOperator !== 'undefined'
  );
}

// Helpers for common conditions

export function fieldValueIsTrueBool(nodeId: string): Condition {
  return {
    nodeId,
    operator: ComparisonOperator.equal,
    value: true,
  };
}

export function fieldValueMaximumCharacters(nodeId: string, maxCharacters: number): Condition {
  return {
    nodeId,
    operator: ComparisonOperator.characterCountBetween,
    value: [-1, maxCharacters + 1],
  };
}

export function fieldValueMinimumCharacters(nodeId: string, minCharacters: number): Condition {
  return {
    nodeId,
    operator: ComparisonOperator.characterCountBetween,
    value: [minCharacters - 1, Number.MAX_SAFE_INTEGER],
  };
}
